<template>
  <div class="w-full">
    <table class="w-full">
      <thead>
        <tr>
          <th class="rounded-t tracking-wide text-3xl md:text-5xl p-4 font-display font-bold text-white bg-golf-green" colspan="5">
            LEADERBOARD
          </th>
        </tr>
        <tr class="text-white">
          <th rowspan="2" class="border border-gray-600 border-t-0 text-white bg-news-black text-left pl-4">Team</th>
          <th colspan="3" class="border border-gray-600 border-t-0 text-white bg-news-black">Day</th>
          <th rowspan="2" class="border border-gray-600 border-t-0 text-white bg-news-black">Overall</th>
        </tr>
        <tr class="text-white">
          <th class="border border-gray-600 text-white bg-news-black">1</th>
          <th class="border border-gray-600 text-white bg-news-black">2</th>
          <th class="border border-gray-600 text-white bg-news-black">3</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="team in tournament.teams" :key="team.name" class="text-news-black">
          <td class="bg-white p-2 pl-4 border text-left">{{ team.name }}</td>
          <td class="bg-white border"><input class="w-8 text-sm text-center" type="text" v-model="team.scores.day1" @change="onChanged"/></td>
          <td class="bg-white border"><input class="w-8 text-sm text-center" type="text" v-model="team.scores.day2" @change="onChanged"/></td>
          <td class="bg-white border"><input class="w-8 text-sm text-center" type="text" v-model="team.scores.day3" @change="onChanged"/></td>
          <td class="bg-white border">{{ team.scores.overall?team.scores.overall:'-' }}</td>
        </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="5" class="rounded-b text-gray-400 p-4 text-sm bg-news-black text-center">Results Updated Each Night</td>
      </tr>
      </tfoot>
    </table>
    <div class="flex gap-4 items-center justify-center w-full">

      <button @click="save" class="p-4 bg-golf-green text-white font-bold text-lg my-4" :disabled="saving"><span v-if="saving">SAVING</span><span v-else>SAVE {{ hasChanges?" CHANGES":""}}</span></button>
    </div>
    <div class="">
      <input type="text" class="p-2 border bg-white" v-model="newTeamName" />
      <button @click="addTeam" class="p-2 border font-bold bg-golf-green text-white border-black hover:bg-news-black" :disabled="saving"><span>ADD TEAM</span></button>
    </div>

  </div>
</template>

<script>
export default {
  name: "LeaderForm",
  data: function() {
    return {
      hasChanges: false,
      saving: false,
      newTeamName: ""
    }

  },
  props: {
    tournament: {
      type: Object,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  methods: {

    addTeam: function(){
        console.log("add team")
      if(this.newTeamName){
        let newTeamId = this.newTeamName.toLowerCase().split("/").map((name)=>{return name.trim()}).join("_");
        this.tournament.teams[newTeamId] = {name: this.newTeamName, id: newTeamId, scores: {day1: 0, day2: 0, day3: 0, overall: 0 }}
        this.newTeamName = ""
      }
    },
    save: async function(){
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            this.tournament
        )
      }
      await fetch("/api/update", options).then((response)=>{
        this.saving = false;
        this.hasChanges = false;
        alert("Update Successful")
        return response.json()
      }).catch((e)=>{
        this.saving = false;
        this.hasChanges = false;
        console.log(e);
        alert("There was a problem.")
      })
    },
    saveOld: function(){
      console.log("save");
      let token = this.token;
      var client = new this.$db.Client({ secret: token })
      var q = this.$db.query;

      return client.query(
          q.Update(
              q.Ref(q.Collection("tournaments"), this.tournament.id),
              {
                data: this.tournament
              }
          )
      ).then(() => {
        this.saving = false;
        this.hasChanges = false;
        alert("Saved changes!");
      }).catch((e) => {
        this.saving = false;
        this.hasChanges = false;
        console.log(e);
        alert("There was a problem.")
      })
    },

    onChanged: function(){
      console.log("changed");
      this.hasChanges = true;
      this.calculate();
    },
    calculate: function(){
      Object.keys(this.tournament.teams).forEach((teamId) => {
        let team = this.tournament.teams[teamId];


        if(!parseInt(team.scores.day1)){
          team.scores.day1 = 0
        }else{
          team.scores.day1 = parseFloat(team.scores.day1);
        }
        if(!parseInt(team.scores.day2)){
          team.scores.day2 = 0
        }else{
          team.scores.day2 = parseFloat(team.scores.day2);
        }
        if(!parseInt(team.scores.day3)){
          team.scores.day3 = 0
        }else{
          team.scores.day3 = parseFloat(team.scores.day3);
        }

        team.scores.overall = parseFloat(team.scores.day1) + parseFloat(team.scores.day2) + parseFloat(team.scores.day3)
      })
    }
  },
  computed: {

  }
}
</script>

<style scoped>

  table tbody tr:first-child {
    font-weight: bold;
    font-size: 1.25rem;
  }


</style>