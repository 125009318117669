<template>
  <div class="home mx-auto max-w-lg text-center">
    <router-link class="text-news-black my-6 block" to="/">
      <h2 class="text-5xl font-serif">Penfield</h2>
      <p class="inline-block text-xl font-serif tracking-wider py-y border-t border-b border-gray-400">COUNTRY CLUB</p>
    </router-link>
    <img class="inline-block max-w-sm" alt="Vue logo" src="../assets/shankertational-hawk.jpg">
    <div class="font-serif text-news-black my-4">
      <p class="text-2xl">2024</p>
      <h1 class="text-2xl md:text-3xl tracking-wide">SHANKERTATIONAL</h1>
    </div>
    <leader-form v-if="tournament && $route.query.t" :token="$route.query.t" :tournament="tournament" class="mb-8"></leader-form>
    <router-link to="/" v-else class="text-lg font-bold m-4 underline">AUTHORIZED USERS ONLY</router-link>

    <div class="my-8 text-sm px-4">
      <p>For more info, contact the site administrator. If you don't know who the site admin is, you don't need to contact him.</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LeaderForm from "@/components/LeaderForm";

export default {
  name: 'Admin',
  components: {
    LeaderForm,
  },
  mounted: async function(){
    this.loadTournament().then((tournament) => {
      this.tournament = tournament;
    }).catch((e) => {
      console.log(e);
    })

  },
  data: function() {
    return  {
      tournamentId: "403685542622920770",
      tournament: undefined,
      teams: [
        {name: "Short / Chantra", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Ball / Zilnik", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Edwards / Edwards", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Shearer / Kelly", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Stoler / Emmick", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Giancursio / Delvecchio", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Grover / Staerr", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Greenwood / Brophy", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Drews / Puccia", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Wagner / Wagner", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }}
      ]
    }
  },
  methods: {

    loadTournament: function(){
      //let token = "fnAEOLHcOIACReLb_A4wD4g0Yl2fhFXOI5MUXouq";
      let token = "fnAFWZwIy5AAQOZUdGFPPk69cVZsgOED3ZvO8hkz";
      var client = new this.$db.Client({ secret: token })
      var q = this.$db.query;

      return client.query(
          q.Get(q.Ref(q.Collection("tournaments"), this.tournamentId))
      ).then((response) => {


        if(response.data){
          let record = response.data;
          record.id = response.ref.value.id;
          return record;
        }else{
          throw new Error("Document error.");
        }
      })
    }
  },
  computed: {

  }

}
</script>

<style scoped>
  .home {

  }
</style>
