import { render, staticRenderFns } from "./LeaderForm.vue?vue&type=template&id=f0696026&scoped=true&"
import script from "./LeaderForm.vue?vue&type=script&lang=js&"
export * from "./LeaderForm.vue?vue&type=script&lang=js&"
import style0 from "./LeaderForm.vue?vue&type=style&index=0&id=f0696026&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0696026",
  null
  
)

export default component.exports